<template>
  <div class="result">
  <div class="bg-white py-12">
  <!-- py-24 sm:py-32" -->
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:text-center">
        <h2 class="text-base font-semibold leading-7 text-indigo-600">당신의 실력은?</h2>
        <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">만능 스포츠인</p>
        <!-- <p class="mt-6 text-lg leading-8 text-gray-600">5문제 중 2문제를 맞추셨어요. 평균 {level} 난이도는 level.4였어요! 또래 나이보다 빨리 풀었어요. {level} 별 평균 풀이 시간 (잘 푸는 문제가 서로 다르니 너무 심여치 마세요!)</p> -->
      </div>
      <!-- <p>{{ quizs }}</p> -->
      <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
        <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
          <div v-for="feature in features" :key="feature.name" class="relative pl-16">
            <dt class="text-base font-semibold leading-7 text-gray-900">
              <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                <component :is="feature.icon" class="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              {{ feature.name }}
            </dt>
            <dd class="mt-2 text-base leading-7 text-gray-600" v-html="feature.description"></dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
    <table class="answers-table">
      <thead>
        <tr>
          <th>번호</th>
          <!-- <th>제출 답안</th>
          <th>실제 답</th> -->
          <th>결과</th>
          <th>소요시간</th>
          <th>정답률</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(answer, idx) in test[0]" :key="answer.id">
          <td>{{ idx + 1 }}</td>
          <!-- <td>{{ answer.user_answer }}</td>
          <td>{{ answer.answer }}</td> -->
          <td>{{ answer.correctYn ? 'O' : 'X' }}</td>
          <td>{{ answer.spentTime }} <img style="width:30px" src="../assets/thumbsup.png" alt="good job"></td>
          <td> - </td>
        </tr>
      </tbody>
    </table>
    <button class="retry" @click='RetryAgain()'>내가 푼 문제 다시풀기</button>
    <div class="actions">
      <button class="retry" @click="Retry()">재응시   |</button>
      <button>다른 문제 풀기   |</button>
      <button class="share" @click="ShareLink()">공유하기   |</button>
      <!-- <button>틀린문제 보러가기(not-yet)   |</button>
      <button>틀린문제 공유하기(not-yet)</button> -->
    </div>
    <FooterVue/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon } from '@heroicons/vue/24/outline'
import { doc, getDoc } from 'firebase/firestore';
import { db } from '@/../firebase'; // Firebase 설정 파일에서 가져오기
import FooterVue from '@/layout/Footer.vue';


export default {
  name: "ResultPage",
  mounted () {
    this.loadRanks()    
  },
  computed: {
    ...mapState({
      remainingTime: state => state.remainingTime, // Vuex 상태에서 remainingTime을 가져옵니다.
      test: state => state.answers
    }),
    //Todo : mapGetters와 mapState 차이
    // ...mapGetters({
    //   answers:'getAnswers'
    // }),
    totalScore() {
      let score = 0;
      if (this.test){
        this.test[0].forEach(answer => {
          if (answer.correctYn) {
            score += 20;
          }
        });
        return score;
      }
      return null;
    },
    features() {
      return [
        {
          name: 'My Point',
          description: `${this.totalScore}점`, // Computed property
          icon: CloudArrowUpIcon,
        },
        {
          name: 'My Time',
          description: `${this.remainingTime}초`, // Vuex state property
          icon: LockClosedIcon,
        },
        {
          name: 'Users Answer Ratio',
          description:
            `풀이한 문제의 평균 난이도는 lev.4 에요.<br>
            또래 중 ${this.peerCorrectYn/5}명이 맞췄어요.<br>
            문제를 풀어본 사람은 ${this.allAttempts/5}명이에요`,
          icon: ArrowPathIcon,
        },
        {
          name: 'Users Time',
          description:
            `또래보다 빨리 풀었어요.<br>
            또래 평균 풀이시간 : ${Math.round(this.peerSpentTime/this.peerAttempts*100)/100}초<br>
            전체 유저 풀이시간 : ${Math.round(this.allSpentTime/this.allAttempts*100)/100}초` ,
          icon: FingerPrintIcon,
        },
      ]
    }
  },
  data() {
    return {
      quizs : [],
      // score : 0,
      allAttempts: 0,
      allSpentTime: 0,
      allCorrectYn: 0,
      peerAttempts: 0,
      peerSpentTime: 0,
      peerCorrectYn: 0,
    };
  },
  components: {FooterVue},
  methods: {
    async loadRanks() {
      try {
        // console.log('loadRanks() >>> answer 리스트 보기 >> ', this.test[0]);
        for (const quiz of this.test[0]) { // for of 가 비동기에 적합
          if (quiz.quizId) {
            const ranksRef = doc(db, 'Ranks', quiz.quizId);
            const quizDocSnap = await getDoc(ranksRef);
            
            if (quizDocSnap.exists()) {
              // console.log(quiz.quizId,'data:', quizDocSnap.data());
              const data = quizDocSnap.data()
              // Todo 유저 나이로 추후에 변경
              const userAge = 'age20'
              Object.entries(data).forEach(([key, value]) => {
                // console.log(`Key: ${key}, Value:`, value);

                this.allAttempts += value['attempts'] // attempts/correctYn = 정답률 
                this.allSpentTime += value['spentTime'] 
                this.allCorrectYn += value['correctYn'] 
                if (key == userAge) {
                  this.peerAttempts += value['attempts'] // attempts/correctYn = 정답률 
                  this.peerSpentTime += value['spentTime'] 
                  this.peerCorrectYn += value['correctYn'] 
                }

              });
              // console.log(quiz.quizId,'allAttempts', allAttempts,Math.ceil(allSpentTime*100)/100,allCorrectYn)
              // console.log(quiz.quizId,'peerAttempts', peerAttempts,Math.ceil(peerSpentTime*100)/100,peerCorrectYn)
              // console.log('==============================')
              this.quizs.push({ id: quiz.quizId, ...quizDocSnap.data() });
              // 추가 로직이 필요한 경우 여기서 처리
            } else {
              console.log('No such document!');
            }
          }
        }
      } catch (e) {
        console.error('loadRank error > ', e);
      }
    },
    ShareLink() {
      if (navigator.share) {
        const info = navigator.share;
        console.log(info)
        navigator.share({
          title: "나의 실력은 어떨까?", // 공유될 제목
          text: this.answers, // 공유될 설명
          url: window.document.location.href, // 공유될 URL
          files: [], // 공유할 파일 배열  
        });
      } else {
        let url = ""; // <a>태그에서 호출한 함수인 clip 생성
        const textarea = document.createElement("textarea");
        //url 변수 생성 후, textarea라는 변수에 textarea의 요소를 생성
        document.body.appendChild(textarea); //</body> 바로 위에 textarea를 추가(임시 공간이라 위치는 상관 없음)
        url = window.document.location.href; //url에는 현재 주소값을 넣어줌
        textarea.value = url; // textarea 값에 url를 넣어줌
        textarea.select(); //textarea를 설정
        document.execCommand("copy"); // 복사
        document.body.removeChild(textarea); //extarea 요소를 없애줌
        alert("링크가 복사되었어요!");
      }
    },
    Retry() {
      window.scrollTo(0, 0);
      // this.$store.dispatch('clearAnswers');
      this.$store.dispatch('clearTime');
      this.$router.push("/questions");
    },
    RetryAgain() {
      //TODO 틀린문제 다시풀기
      window.scrollTo(0, 0);
      console.log('RetryAgain log >>> ', this.test[0])

      const paramLst = []
      this.test[0].forEach(quiz => {
        if (quiz.quizId) {
          paramLst.push(quiz.quizId)
        }
      });
      // quizId 리스트를 query로 변환하여 '/questions'로 이동
      const queryParams = paramLst.length ? `?quizIds=${paramLst.join(',')}` : '';
      console.log('result.veu >> queryParams: ', queryParams)
      this.$router.push(`/questions${queryParams}`);
    },
    Redirect() {
      // const REDIRECT_URL = "https://khvd.kr/project/81";
      // window.open(REDIRECT_URL);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.result {
  padding-bottom: 120px;
  min-height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #0371e8;
}
.tag {
  margin: 6.75rem 0 0 0;
  font-weight: 500;
  font-size: 0.938rem;
}
.type {
  font-weight: 800;
  font-size: 1.875rem;
}
.img {
  margin: 0.975rem 0 0.812rem 0;
  width: 12.256rem;
}
.description {
  white-space: pre-line;
  font-weight: 600;
  font-size: 0.813rem;
  line-height: 1.54;
  letter-spacing: -0.26px;
  margin: 0 0 2.313rem 0;
}
.strong {
  background-color: #0371e8;
  color: white;
  width: 3.813rem;
  height: 1.563rem;
  border-radius: 13px;
  font-size: 0.938rem;
  font-weight: bold;
  line-height: 1.47;
  display: flex;
  justify-content: center;
  align-items: center;
}
.strong-content {
  white-space: pre-line;
  font-size: 0.75rem;
  margin: 0.813rem 0 1.563rem 0;
  line-height: 1.83;
  letter-spacing: -0.16px;
}
.weak {
  background-color: #0371e8;
  color: white;
  width: 3.813rem;
  border-radius: 13px;
  font-size: 0.938rem;
  font-weight: bold;
  height: 1.563rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.weak-content {
  white-space: pre-line;
  font-size: 0.75rem;
  line-height: 1.83;
  letter-spacing: -0.16px;
  margin: 0.813rem 0 2.375rem 0;
}
.copy {
  white-space: pre-line;
  font-weight: bold;
  font-size: 0.938rem;
  margin: 0 0 2.063rem 0;
}
.btn-container {
  display: flex;
  flex-direction: column;
  width: 8.25rem;
  color: white;
  justify-content: space-between;
  height: 8.5rem;
  margin: 0 0 4.875rem 0;
}
.buy {
  background-color: #0371e8;
  font-size: 0.813rem;
  font-weight: 300;
  height: 2.313rem;
  border-radius: 10px;
}
.retry {
  background-color: #afceff;
  font-size: 0.813rem;
  font-weight: 300;
  height: 2.313rem;
  border-radius: 10px;
}
.share {
  background-color: #afceff;
  font-size: 0.813rem;
  font-weight: 300;
  border-radius: 10px;
  height: 2.313rem;
}
/* gpt add */
.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.answers-table {
  width: 40%;
  border-collapse: collapse;
  margin: 20px 0;
}

.answers-table th, .answers-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.answers-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.score-container, .rankings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.score-label, .time-label, .ranking-label {
  font-weight: bold;
  margin-right: 5px;
}

.score, .time {
  font-size: 1.2em;
}
</style>
