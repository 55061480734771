<template>
  <div class="survey-container">
    <div class="grid grid-cols-1">
      <div class="grid도입실험중">
        <!-- grid도입실험중 -->
        <div class="nav">
          <!-- progress bar -->
          <div class="progress-bar py-10 flex-none">
            <div class="background-bar w-full bg-[#f3f3f3] rounded-full h-3.5">
              <div
                class="blue-bar rounded bg-[#83b3ff] h-3.5 transition-all"
                :style="{ width: (page / (questions.length - 1)) * 100 + '%' }"
              ></div>
            </div>
          </div>
          <TimerComponent ref="timerComponent"/>
          <!-- prev button -->
          <div class="text-left flex-none">
            <button @click="ShowPrevPage()" class="btn-prev" v-if="page">
              <img src="../assets/btn-prev.svg" />
            </button>
            <!-- indicator -->
            <p class="indicator">
              <span class="text-custom-blue font-bold">{{ page + 1 }}</span>/5
            </p>
          </div>
        </div>
        <!-- Question -->
        <div class="questions_container overflow-auto">
          <div class="q" :key="GenerateKey(page)">
            <div class="question__number" :class="{ done: isChecked(page) }">
              Q{{ page + 1 }}
            </div>
            <div class="question__content" :class="{ done: isChecked(page) }">
              <img :src="questions[page]?.imgUrl" alt="Question Image">
              <!-- <img :src="require(`@/assets/${questions[page].image}`)" alt="Question Image"> -->
            </div>
            <!-- option -->
            <div class="options" style="max-width: 400px;">
              <fieldset>
                <input
                  type="radio"
                  :id="'option1_' + page"
                  :name="page"
                  v-model="selected[page]"
                  value=1
                  @click="HandleAnswerClick"
                  required
                />
                <label :for="'option1_' + page">1</label>
                <input
                  type="radio"
                  :id="'option2_' + page"
                  :name="page"
                  v-model="selected[page]"
                  value=2
                  @click="HandleAnswerClick"
                  required
                />
                <label :for="'option2_' + page">2</label>
                <input
                  type="radio"
                  :id="'option3_' + page"
                  :name="page"
                  v-model="selected[page]"
                  value=3
                  @click="HandleAnswerClick"
                  required
                />
                <label :for="'option3_' + page">3</label>
                <input
                  type="radio"
                  :id="'option4_' + page"
                  :name="page"
                  v-model="selected[page]"
                  value=4
                  @click="HandleAnswerClick"
                  required
                />
                <label :for="'option4_' + page">4</label>
                <input
                  type="radio"
                  :id="'option5_' + page"
                  :name="page"
                  v-model="selected[page]"
                  value=5
                  @click="HandleAnswerClick"
                  required
                />
                <label :for="'option5_' + page">5</label>
              </fieldset>
            </div>
          </div>
        </div>
        <div class="btn-next__container">
          <button class="btn-next" type="submit" @click="ShowNextPage()">
            다음
          </button>
        </div>
      </div>
      <div>
        <FooterVue />
      </div>
    </div>
  </div>
</template>

<script>
import FooterVue from '@/layout/Footer.vue';
// import dummyQuestion from '../assets/dummyQuestion.json';
import TimerComponent from './TimerComponent.vue';
import { mapState } from 'vuex';
import { collection, addDoc, getDocs, query, Timestamp, doc, setDoc, increment, where } from 'firebase/firestore';
import { db } from '@/../firebase'; // Firebase 설정 파일에서 가져오기


export default {
  name: 'QuestionsPage',
  components: {
    FooterVue,
    TimerComponent,
  },
  mounted: function () {
      // Todo >> 새로고침했을때 문제 안바뀌게
      this.$store.dispatch('clearAnswers');
      this.$store.dispatch('clearTime');
      this.startTime = Date.now();
      // this.loadRandomQuestions();
      const queryParams = this.$route.query.quizIds;
      if (queryParams) {
        const quizIds = queryParams.split(',');
        this.loadQuestionsByIds(quizIds);
      } else {
        this.loadRandomQuestions();
      }
    },
  watch: {
    questions(newQuestions) {
      if (newQuestions.length > 0) {
        // 데이터가 로드된 후 처리할 로직
        console.log('Questions loaded:', newQuestions);
      }
    }
  },
  computed: {
    ...mapState({
      remainingTime: state => state.remainingTime // Vuex 상태에서 remainingTime을 가져옵니다.
    })
  },
  methods: {
    async loadQuestionsByIds(quizIds) {
      try {
        const quizzesRef = collection(db, 'Quizs');
        const q = query(quizzesRef, where('__name__', 'in', quizIds)); // quizId로 조회
        const querySnapshot = await getDocs(q);
        console.log('questions.vue >> QuizbyId querySnapshot : ', querySnapshot)
        if (querySnapshot.size != 5){
          console.log('BAD Reqeust')
        } else {
          for (const doc of querySnapshot.docs) {
            const quizData = doc.data();
            this.questions.push({ id: doc.id, ...quizData });
          }
        }
      } catch (error) {
        console.error('Error loading questions by IDs: ', error);
      }
    },
    async loadRandomQuestions() {
      try {
        const quizzesRef = collection(db, 'Quizs');

        // Todo >> 비용처리 : 모든 문서를 먼저 가져온 후 무작위로 5개의 문서를 선택
        const allDocsSnapshot = await getDocs(quizzesRef);
        const allDocs = allDocsSnapshot.docs;

        if (allDocs.length < 5) {
          console.log('Not enough quizzes in the collection');
          return;
        }

        // 무작위로 5개의 문서를 선택
        const randomDocs = allDocs.sort(() => 0.5 - Math.random()).slice(0, 5);

        for (const doc of randomDocs) {
          const quizData = doc.data();
          this.questions.push({ id: doc.id, ...quizData });
        }
      } catch (error) {
        console.error('Error loading random questions: ', error);
      }
    },
    // async loadRandomQuestions() {
    //   try {
    //     const quizzesRef = collection(db, 'Quizs');
    //     const q = query(quizzesRef, limit(5)); // 5개의 문서만 가져옴
    //     const querySnapshot = await getDocs(q);
    //     console.log(querySnapshot)
    //     for (const doc of querySnapshot.docs) {
    //       const quizData = doc.data();
    //       // console.log('quizData>>>',quizData)
    //       this.questions.push({ id: doc.id, ...quizData });
    //     }
    //   } catch (error) {
    //     console.error('Error loading questions: ', error);
    //   }
    // },
    handleStopTimer() {
      this.$refs.timerComponent.stopTimer();
    },
    ShowNextPage() {
    if (this.selected[this.page] === undefined) {
      alert('모든 문제에 답안을 제출해주세요.');
      return;
    }

    // 현재 질문의 소요 시간 계산
    const endTime = Date.now();
    const spentTime = parseFloat(((endTime - this.startTime) / 1000).toFixed(2)); // 소요 시간을 초 단위로 계산
    const correctYn = this.selected[this.page] === parseInt(this.questions[this.page].answer);
    console.log('correctYn !! selected :',this.selected[this.page],'//answer: ',this.questions[this.page].answer )
    this.answers.push({
      quizId: this.questions[this.page].id,
      correctYn : correctYn,
      // user_answer: this.selected[this.page],
      // answer: this.questions[this.page].answer,
      spentTime: spentTime, // 소요 시간 저장
    });

    if (this.page < 4) {
      this.page += 1;
      this.startTime = Date.now(); // 다음 질문 시작 시간 기록
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    } else {
      this.CollectAnswers(); // 마지막 페이지일 때 응답을 수집
      this.handleStopTimer();
      // console.log('@@ this.answers >> ', this.answers);
  
      //Todo >> 전송방식 바꿀것 url에 노출되는거 너무 꼴뵈기 싫음
      this.$router.push({ name: '로딩'});
    }
  },
  ShowPrevPage() {
    if (this.page) {
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      this.page -= 1;
    } else {
      history.back();
    }
  },
  HandleAnswerClick(event) {
    const questionNumber = this.page;
    const answer = parseInt(event.currentTarget.value);
    this.selected[questionNumber] = answer;
  },
  isChecked(i) {
    return this.selected[i] ? true : false;
  },
  GenerateKey(i) {
    const uniqueKey = 'Q:' + i + ' A:' + this.selected[i];
    return uniqueKey;
  },
    // TODO 여기서 계산을 하자.
    // 그러면 채점을 해서 보내주자,
    // 문제별 정답여부
    // {questions : {1,0,0,1,1}}
  async CollectAnswers() {
    this.$store.dispatch('addAnswer', this.answers);
    console.log('question.vue > Collect & Save answers:', this.answers);
    // 여기에서 로더로 응답을 전송할 수 있습니다.
    try {
      // Firestore에 User 데이터 저장
      const today = Timestamp.now();
      await addDoc(collection(db, 'Users'), {
        age : 19,
        answers : this.answers,
        regDate : today
      });
    } catch (e) {
      console.error('Error adding document: ', e);
    }
    // try {
    //   // Firestore에 Ranks 데이터 저장
    //   this.answers.forEach(submit => {
    //     console.log('submit.quizId >> ',submit.quizId)
    //     // addDoc(collection(db, 'Ranks'), {
    //     //   age : 20,
    //     //   answers : this.answers,
    //     // });
    //   })
    //   console.log('FireStore Rank info');
    // } catch (e) {
    //   console.error('Error adding document: ', e);
    // }
    try {
        const currentUserAge = 19; // 나중에 실제 사용자 나이로 교체 가능
        
        for (const answer of this.answers) {
          const quizDocRef = doc(db, 'Ranks', answer.quizId);  // Ranks/quizId 문서 참조
          
          // 'age' 필드 업데이트
          const ageField = `age${currentUserAge}`; // 나이를 동적으로 key로 설정
          const correctIncrement = answer.correctYn ? 1 : 0; // 맞춘 경우 correct 1 증가, 틀리면 0
          const correctTime = answer.correctYn ? answer.spentTime : 0; // 맞춘 경우 correct 1 증가, 틀리면 0

          // 문서가 없으면 생성하고 있으면 필드만 업데이트
          await setDoc(quizDocRef, {
            [ageField]: {
              correctYn: increment(correctIncrement),  // 정답 횟수 증가
              spentTime: increment(correctTime),
              attempts: increment(1)  // 시도 횟수 증가
            },
            // time: {
            //   lastAttempt: now  // 마지막 시도 시간 업데이트
            // }
          }, { merge: true });  // 기존 데이터와 병합
        }
      } catch (e) {
        console.error('Error adding document: ', e);
      }
  },
},
  data() {
    return {
      page: 0,
      type: undefined,
      questions: [],
      selected: new Array(5), // 질문이 5개이므로 크기를 5로 설정
      answers: [], // 질문 ID와 응답을 저장할 배열
      startTime: null,
    };
  },
};
</script>

<style scoped>
.survey-container {
  padding-bottom: 120px;
  min-height: 90%;
  margin: 0 9%;
  display: flex;
  flex-direction: column;
}
.nav {
  position: sticky;
  top: 0;
  background-color: white;
  padding: 0 0 1.563rem 0;
}
.background-bar {
  overflow: hidden;
}
.btn-prev {
  position: absolute;
}
.indicator {
  text-align: center;
}
.question__number {
  font-size: 1.875rem;
  font-weight: 900;
  text-align: left;
  line-height: 0.9;
  letter-spacing: -0.75px;
  margin: 0 0 0.625rem 0;
}
.question__content {
  font-size: 1.063rem;
  font-weight: bold;
  text-align: left;
  line-height: 1.41;
  min-height: 15rem;
  letter-spacing: -0.43px;
  margin: 0 0 1.313rem 0;
  white-space: pre-line;
}
.done {
  color: #afafaf;
}
.options {
  margin: 0.5rem;
}
fieldset {
  margin: 0 0 3.75rem 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
input[type='radio'] {
  display: none;
}
label {
  font-size: 0.813rem;
  height: 1.75rem;
  padding: 0 1.375rem;
  border: 1px solid #83b3ff;
  border-radius: 7px;
  display: flex;
  align-items: center;
}
input[type='radio']:checked + label {
  background: #0371e8;
  color: white;
}
.btn-next {
  font-weight: 600;
  color: white;
  background-color: #0371e8;
  width: 10.875rem;
  padding: 1rem 0rem;
  border-radius: 7px;
  font-size: 0.813rem;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  margin: 0 0 6.25rem 0;
}
</style>
