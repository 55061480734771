<template>
  <footer
    class="footer fixed bottom-0 left-0 z-20 w-full bg-white border-t border-gray-200 shadow dark:bg-gray-800 dark:border-gray-600"
  >
    <div class="w-full max-w-screen-xl mx-auto flex flex-col items-center justify-center p-4">
      <a href="" class="flex items-center space-x-3 rtl:space-x-reverse mb-4">
        <img src="../assets/logo.png" class="h-10" alt="Flowbite Logo" />
        <span class="text-2xl font-semibold whitespace-nowrap dark:text-white">Ssolplan</span>
      </a>
      <span class="text-sm text-gray-500 dark:text-gray-400 text-center">
        © 2024 <a href="" class="hover:underline">Ssolplan</a>. All Rights Reserved.
      </span>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterPage",
};
</script>

<style scoped>
.footer {
  min-height: 5%; /* footer의 높이 조정 */
  height: auto; /* 내용에 따라 높이 자동 조절 */
}
</style>
