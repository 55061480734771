<template>
  <div class="main-container">
    <!-- <div class="title">나의 실력은<br />어떨까?</div> -->
    <div class="bg-white py-24 sm:py-32">
      <div class="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
        <div class="max-w-2xl">
          <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">나의 실력은 어떨까?</h2>
          <p class="mt-6 text-lg leading-8 text-gray-600">심심풀이 문제풀기</p>
        </div>
        <ul role="list" class="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
          <li v-for="item in menus" :key="item.name">
            <div class="flex items-center gap-x-6">
              <img class="h-16 w-16 rounded-full" :src="item.imageUrl" alt="" />
              <div>
                <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">{{ item.name }}</h3>
                <p class="text-sm font-semibold leading-6 text-indigo-600">{{ item.description }}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <router-link
      class="btn text-white text-[0.813rem] font-bold rounded-md bg-custom-blue py-[0.8rem] w-[39.5%] h-[4.7%]"
      to="/questions">
      바로 시작하기
    </router-link>
    <FooterVue />
  </div>
</template>

<script>
import FooterVue from '@/layout/Footer.vue';
export default {
  name: "MainPage",
  components: {FooterVue},
  props: {},
  data () {
    return {
      menus : [
        {
        name: '몸풀기',
        description: '2점',
        imageUrl:
          'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
        name: '암산의한계',
        description: '2~3점',
        imageUrl:
          'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
        name: '현역',
        description: '3~4점',
        imageUrl:
          'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
        name: '킬러',
        description: '킬러',
        imageUrl:
          'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
        name: '국내',
        description: '올림피아드',
        imageUrl:
          'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
        name: '슈퍼스타',
        description: '난제',
        imageUrl:
          'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
      ]
    }
  }
  // metaInfo() {
  //   return {
  //     title: "Ssolplan - 내 유형 알아보기",
  //     description: "나의 실력은 어떨까?",
  //     meta: [
  //       { name: "og:title", content: "내 유형 알아보기" },
  //       { name: "og:site_name", content: "Ssolplan" },
  //       { name: "og:description", content: "나의 실력은 어떨까" },
  //       {
  //         name: "og:image",
  //         content:
  //           "https://raw.githubusercontent.com/HiBixby/LazyMaker_Vue/fa889c38f1397e5148c8855a98652a5ed2855c9a/src/assets/logo.svg",
  //       },
  //     ],
  //   };
  // },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-container {
  padding-bottom: 120px;
  min-height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title {
  width: 10.625rem;
  height: 3.938rem;
  margin: 0rem 0 2.398rem 0;
  font-size: 1.688rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: -0.68px;
  text-align: center;
}
.logo {
  width: 13.75rem;
  margin: 0 0 0.688rem 0;
}
.btn {
  width: 9.625rem;
  margin: 0;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
}
</style>
